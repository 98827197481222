.chatbox {
  background: var(--primary-bg-color-light);
  padding: 60px 30px 0px 30px;
  height: 100vh;
}
.chatmessages_container {
  flex: auto;
  flex-wrap: wrap;
  overflow: auto;
  position: relative;
  padding: 20px 0px;
}
.preview {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;
}
.preview header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: var(--secondary-bg-color);
  padding: 20px 10px;
  color: var(--primary-font-color);
  font-size: 22px;
  font-family: circular-regular;
}
.preview main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview header span {
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}
.preview .attachmentSendBtn {
  background: var(--primary-font-color);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.preview footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.chatmessages_container::-webkit-scrollbar {
  display: none;
}
.incoming_msg,
.outgoing_msg {
  display: flex;
  max-width: 400px;
  gap: 10px;
  margin-top: 20px;
}
.incoming_msg div,
.outgoing_msg div {
  background: white;
  padding: 10px;
  border-radius: 0px 15px 15px 15px;
  margin-top: 28px;
}
.incoming_msg p,
.outgoing_msg p,
.outgoing_msg a,
.incoming_msg a {
  font-family: circular-regular;
  font-size: 16px;
  line-height: 25px;
}
.outgoing_msg a,
.incoming_msg a {
  color: #11439c;
  text-decoration: none;
}
.incoming_msg span,
.outgoing_msg span {
  font-size: 14px;
  font-family: circular-regular;
  opacity: 0.5;
  padding-top: 10px;
  display: inline-block;
}
.outgoing_msg div {
  border-radius: 15px 0px 15px 15px;
  background: var(--secondary-bg-color-medium);
}
.sendmsg {
  display: flex;
  align-items: center;
}
.sendmsg input {
  flex: auto;
  border: none;
  outline: none;
  background: var(--secondary-font-color);
  height: 50px;
  padding: 0px 10px;
  font-size: 18px;
  font-family: circular-medium;
  width: 100%;
}
.sendmsg input::placeholder {
  font-size: 16px;
  opacity: 0.6;
}
.sendmsg button {
  background: var(--primary-bg-color);
  font-size: 18px;
  font-family: circular-medium;
  color: white;
  padding: 0px 30px;
  height: 50px;
}
.sendmsg button img {
  margin-left: 5px;
}
.scrolltobottom {
  height: 100%;
}
.scrolltobottominner::-webkit-scrollbar {
  display: none;
}
.attachment {
  background: var(--secondary-font-color);
  height: 50px;
}
.attachment_inner {
  background: #22222214;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
}
