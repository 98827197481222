* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/******** VARIABLES ********/
:root {
  --primary-bg-color: #0b5a56;
  --primary-bg-color-light: #0b5a5614;
  --secondary-bg-color: #fdf2e6;
  --secondary-bg-color-medium: #fadec0;
  --primary-font-color: #0b5a56;
  --secondary-font-color: #f4bc80;
}

/******** FONTS *********/
@font-face {
  font-family: circular-bold;
  src: url('./assets/fonts/Circular\ Std\ Bold.ttf');
}
@font-face {
  font-family: circular-regular;
  src: url('./assets/fonts/Circular\ Std\ Book.ttf');
  font-weight: normal;
}
@font-face {
  font-family: circular-medium;
  src: url('./assets/fonts/Circular\ Std\ Medium.ttf');
  font-weight: normal;
}
@font-face {
  font-family: buckley;
  src: url('./assets/fonts/buckley');
}

/******* GENERAL *******/
.img_fluid {
  max-width: 100%;
  height: auto;
}
.text_center {
  text-align: center;
}
.btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.rating .MuiSvgIcon-root {
  stroke: var(--secondary-font-color) !important;
}
.session_rating .MuiSvgIcon-root {
  stroke: var(--secondary-font-color) !important;
}
.session_rating .MuiRating-iconEmpty {
  color: transparent !important;
}
.cursor_pointer {
  cursor: pointer;
}
.custom_label .MuiFormControlLabel-label {
  font-family: circular-regular;
}
.d_none {
  display: none;
}
.fit_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* MYSESSIONS */
.session_details_inner {
  padding: 30px;
  height: 90vh;
  overflow-y: auto;
}
.session_details_inner::-webkit-scrollbar {
  display: none;
}
