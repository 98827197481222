/****** LAYOUT ******/
:root {
  --nav-width: 260px;
}
.root {
  display: flex;
}
.main_content {
  flex: 1;
  margin-left: var(--nav-width);
}

/****** SIDENAV ******/
.sidenav {
  width: var(--nav-width);
  height: 100vh;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  overflow-y: auto;
  padding-bottom: 20px;
}
.sidenav::-webkit-scrollbar {
  display: none;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tags div {
  font-family: circular-regular;
  font-size: 13px;
  background: #f2f2f2;
  padding: 8px 10px;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.3s;
}
.active_tag {
  background: var(--secondary-bg-color) !important;
  color: var(--primary-font-color);
  font-family: circular-medium !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/********* TEACHERS **********/
.teachers .teacher_card {
  box-shadow: 0px 5px 15px #00000027;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  padding: 16px;
  max-width: 70%;
}
.qualification {
  border: 1px solid var(--secondary-font-color);
  border-radius: 20px;
  padding: 4px 10px;
}
.teacher_card .price {
  background: var(--secondary-font-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 30px 20px;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 10px;
}
.line {
  background: black;
  opacity: 0.2;
  width: 2px;
}
.knowmore_btn {
  background: var(--primary-bg-color);
  color: var(--secondary-font-color);
  font-family: circular-regular;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

/****** MEDIA QUERIES *****/
@media (max-width: 1400px) {
  .teachers .teacher_card {
    max-width: 85%;
  }
}
@media (max-width: 1200px) {
  .teachers .teacher_card {
    max-width: 95%;
  }
}
