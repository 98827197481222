/********* GENERAL **********/
.title_white {
  font-size: 50px;
  line-height: 60px;
  color: white;
  font-family: buckley;
}
.title_primary {
  font-size: 50px;
  line-height: 60px;
  color: var(--primary-bg-color);
  font-family: buckley;
}
.primary_medium_title {
  font-size: 22px;
  font-family: circular-medium;
  color: var(--primary-bg-color);
}
.secondary_medium_title {
  font-size: 22px;
  font-family: circular-medium;
  color: var(--secondary-font-color);
}
.black_medium_title {
  font-size: clamp(16px, 1.1vw, 22px);
  line-height: 40px;
  font-family: circular-regular;
  color: black;
  font-weight: lighter;
}
.para_black {
  font-size: 16px;
  line-height: 30px;
  font-family: circular-regular;
}
.para_white {
  font-size: 16px;
  line-height: 30px;
  color: white;
  font-family: circular-regular;
}
.main_bg {
  background: var(--primary-bg-color);
}
.secondary_bg {
  background: var(--secondary-bg-color);
}
.white_bg {
  background: white;
}
.text_center {
  text-align: center;
}
.pt1 {
  padding-top: 10px;
}
.pt2 {
  padding-top: 20px;
}
.o7 {
  opacity: 0.7;
}
.block {
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.perfect_center {
  display: grid;
  place-items: center;
}

/******** BANNER ********/
.banner {
  min-height: 100vh;
}
.btn {
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: circular-bold;
  cursor: pointer;
}
.header .teacher_login_btn {
  color: white;
}
.header .get_started_btn {
  background: var(--secondary-font-color);
  color: var(--primary-font-color);
  padding: 10px 20px;
  border-radius: 10px;
}
.search_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.search_container .search_container_inner {
  width: 70%;
  margin: auto;
}
.input_container {
  position: relative;
}
.search_container input {
  background: none;
  border: none;
  border: 1px solid white;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  outline: none;
  padding-left: 20px;
  padding-right: 40px;
  margin-top: 10px;
  color: white;
  font-family: circular-medium;
  font-size: 16px;
}
.search_container input::placeholder {
  color: white;
  font-family: circular-medium;
  opacity: 0.7;
  font-size: 16px;
}
.search_container h4 {
  color: var(--secondary-font-color);
  font-size: 22px;
  font-family: circular-bold;
}
.search_container span {
  position: absolute;
  top: 24px;
  right: 10px;
}
.book_session_btn {
  background: var(--secondary-font-color);
  color: var(--primary-font-color);
  padding: 10px 20px;
  border-radius: 10px;
}
.subjects h3 {
  color: white;
  opacity: 0.6;
  font-family: circular-regular;
  font-weight: normal;
  font-size: 16px;
}
.subjects .subjects_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.subjects .subjects_container div {
  font-family: circular-medium;
  border: 1px solid var(--secondary-font-color);
  border-radius: 15px;
  padding: 13px 16px;
  color: white;
  margin: 20px 10px 0px 0px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
}

/********** MAIN ***********/
.about,
.become_techer,
.student_features,
.how_it_works,
.download,
.verfied_teachers {
  padding: 100px 0px;
}
.student_features .get_started_btn,
.verfied_teachers .book_session {
  margin-top: 20px;
  background: var(--primary-bg-color);
  color: var(--secondary-font-color);
  padding: 10px 20px;
  border-radius: 10px;
}
.search_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.search_container .search_container_inner {
  width: 70%;
  margin: auto;
}
.input_container {
  position: relative;
}
.search_container input {
  background: none;
  border: none;
  border: 1px solid var(--primary-font-color);
  width: 100%;
  height: 50px;
  border-radius: 10px;
  outline: none;
  padding-left: 30px;
  padding-right: 40px;
  margin-top: 10px;
  color: black;
  font-family: circular-medium;
  font-size: 16px;
}
.search_container input::placeholder {
  color: black;
  font-family: circular-medium;
  opacity: 0.7;
  font-size: 16px;
}
.search_container h4 {
  color: var(--primary-font-color);
  text-align: center;
  font-size: 22px;
  font-family: circular-bold;
}
.search_container img {
  position: absolute;
  top: 24px;
  left: 10px;
}
.search_container button {
  position: absolute;
  top: 20px;
  right: 10px;
  background: var(--primary-bg-color);
  color: var(--secondary-font-color);
  padding: 6px 16px;
  font-family: circular-regular;
  border-radius: 6px;
}
.arrow1,
.arrow2,
.arrow3 {
  position: absolute;
}
.arrow3 {
  top: 40%;
  left: 25%;
}
.arrow2 {
  top: 40%;
  right: 25%;
}
.arrow1 {
  bottom: 10%;
  left: 45%;
}
.become_techer .form {
  width: 50%;
  max-width: 380px;
  margin: auto;
}
.become_techer .input_container {
  position: relative;
  margin-top: 40px;
}
.become_techer .input_container span {
  position: absolute;
  left: 10px;
  top: -10px;
  background: var(--primary-bg-color);
  padding: 0px 6px;
  font-family: circular-regular;
  color: rgba(255, 255, 255, 0.7);
}
.become_techer input,
.become_techer textarea {
  height: 50px;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0px 10px;
  font-family: circular-regular;
  color: white;
  font-size: 16px;
}
.become_techer textarea {
  height: 100px;
  padding: 10px;
  resize: none;
}
.btn_container {
  margin-top: 20px;
}
.btn_container button {
  width: 100%;
  background: var(--secondary-font-color);
  color: var(--primary-font-color);
  font-size: 18px;
  border: none;
  font-family: circular-medium;
  padding: 10px 0px;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}
.footer {
  margin-top: 100px;
}
.footer .line {
  height: 1px;
  background: var(--secondary-font-color);
  margin-top: 7px;
  opacity: 0.7;
}
.footer div.line:nth-child(2) {
  opacity: 1;
}
.footer .links {
  display: flex;
  justify-content: space-between;
}
.footer .links a {
  font-size: 16px;
  color: white;
  opacity: 0.7;
  font-family: circular-medium;
  text-decoration: none;
}
