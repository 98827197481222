.tab {
  position: relative;
}
.tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: lightgrey;
  height: 2px;
  bottom: -10px;
}
.active_tab::before {
  position: absolute;
  content: '';
  width: 100%;
  background: var(--secondary-font-color);
  height: 3px;
  bottom: -10px;
}
