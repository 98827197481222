/****** LAYOUT ********/
.root {
  display: flex;
}
.main_content {
  flex: 1;
}
.sidenav {
  flex-shrink: 0;
  width: 260px;
  height: 100vh;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.sidenav .logo {
  padding-left: 30px;
  padding-top: 20px;
}
.sidenav .links .menu_item,
.sidenav .bottom_links .menu_item {
  font-family: circular-regular !important;
  font-size: 18px !important;
  opacity: 0.5;
}
.sidenav .bottom_links {
  position: absolute;
  bottom: 30px;
}
.sidenav .bottom_links .menu_item {
  opacity: 0.6;
  font-size: 14px !important;
}
.profile {
  padding-left: 30px;
}
.profile h6 {
  font-size: 20px;
  font-family: circular-regular;
  font-weight: normal;
}
.profile span {
  font-size: 16px;
  font-family: circular-regular;
  color: var(--primary-bg-color);
  cursor: pointer;
  padding-top: 5px;
}

/******** GENERAL **********/
.page_title {
  font-size: 30px;
  font-family: circular-medium;
  line-height: 36px;
  font-weight: lighter;
}
.section_title {
  font-size: 16px;
  opacity: 0.5;
  font-family: circular-medium;
}
.secondary_title {
  font-size: 18px;
  font-family: circular-medium;
  font-weight: normal;
}
.secondary_title_primary {
  font-size: 18px;
  font-family: circular-medium;
  color: var(--primary-font-color);
  font-weight: normal;
}
.primary_btn {
  padding: 10px 20px;
  background: var(--primary-bg-color);
  color: var(--secondary-font-color);
  font-size: 18px;
  font-family: circular-bold;
  border-radius: 10px;
  min-width: 120px;
}
.overflow_section {
  height: 100vh;
  padding: 30px;
  overflow-y: auto;
}
.overflow_section::-webkit-scrollbar {
  display: none;
}
