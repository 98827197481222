* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.video_container {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url('https://images.unsplash.com/photo-1490365728022-deae76380607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: 0.5s;
}
.video_wrapper {
  width: 100%;
  overflow: hidden;
}
.show_screen_text {
  display: block;
  position: fixed;
  left: 40%;
  top: 40%;
  color: whitesmoke;
  font-size: 2vw;
  font-weight: bolder;
}

.hide_screen_text {
  display: none;
}
.video_container_move {
  width: 75%;
  height: 100vh;
  background: red;
  position: relative;
}

.video_topright_icons {
  position: absolute;
  z-index: 50;
  display: flex;
  gap: 10px;
  top: 0;
  right: 0;
  background: #ffffff;
  border-radius: 0px 0px 0px 15px;
  padding: 10px;
  cursor: pointer;
}
.video_topright_icons_attach {
  top: 0;
  right: 150px;
}
.video_topleft_icon {
  position: fixed;
  display: flex;
  gap: 5;
  align-items: center;
  top: 5px;
  color: red;
  font-weight: bolder;
}
.video_topleft_icon_hide {
  display: none;
}
.chat_container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25%;
  background: var(--primary-bg-color-light);
  overflow: hidden;
  transition: 0.5s;
  transform: translateX(100%);
}
.chat_container_active {
  transform: translateX(0%);
}
.control_bar {
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}
.video_actions_middle,
.video_actions_right {
  display: flex;
  gap: 20px;
}
.main_container {
  position: relative;
}
.video_actions_right {
  color: #0b5a56;
}
.video_actions_right p,
.video_actions_right span {
  font-size: 14px;
  text-align: center;
  font-family: circular-regular;
}
.video_session_details {
  display: flex;
  align-items: center;
}
.iconStyleOff,
.iconStyleOn,
.videoActionOn {
  cursor: pointer;
}
.iconStyleOff {
  background-color: #f2363d;
  color: white;
  border-radius: 50%;
  padding: 8px;
}
.iconStyleOn {
  background-color: #616161;
  color: white;
  border-radius: 50%;
  padding: 8px;
}
.videoActionOn {
  color: #0b5a56;
  padding: 4px;
}
.callEndIcon {
  cursor: pointer;
  color: white;
  background: #f89a9e;
  border-radius: 50%;
  padding: 8px;
}
@media (max-width: 768px) {
  .video_actions_right span:not(:last-child) {
    display: none;
  }
  .control_bar {
    padding: 5px 0px;
  }
  .video_session_details {
    display: none;
  }
  .fullscreenText {
    display: none;
  }
  .video_logo {
    display: none;
  }
  .video_topright_icons {
    right: 140px;
  }
  .video_container {
    width: 100%;
    height: 70vh;
    background-color: red;
  }
  .chat_container {
    position: absolute;
    top: 70vh;
    width: 100%;
    height: 30vh;
    width: 100%;
  }
  .video_topright_icons {
    display: none;
  }
}
