.login {
  background: var(--secondary-bg-color);
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.login header {
  position: absolute;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.login_form {
  width: 80%;
  max-width: 500px;
  background: white;
  padding: 50px 50px 100px 50px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}
.register_form {
  max-width: 800px !important;
  padding: 50px !important;
}
.registerLabel {
  background: var(--secondary-font-color);
  color: var(--primary-font-color);
  font-size: 18px;
  font-family: circular-medium;
  padding: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
