.student_name {
  font-size: 18px;
  font-family: circular-regular;
  font-weight: normal;
}
.session_card {
  background: var(--secondary-bg-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  font-family: circular-regular;
}
.session_card p {
  padding-top: 5px;
}
.no_of_hours {
  font-size: 18px;
  font-family: circular-regular;
  font-weight: normal;
  color: var(--primary-font-color);
  padding-top: 10px;
}
