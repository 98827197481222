.teacher_login {
  background: var(--secondary-bg-color);
  min-height: 100vh;
  display: grid;
  place-items: center;
}
.teacher_login header {
  position: absolute;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}
.teacher_login .teacher_login_btn {
  color: var(--secondary-font-color);
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: circular-bold;
  cursor: pointer;
}
.teacher_login_form {
  width: 80%;
  max-width: 500px;
  background: white;
  padding: 50px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  margin: 50px 0px;
}
.teacher_register_form {
  max-width: 1000px !important;
  padding: 100px !important;
}
.text_center {
  text-align: center;
}
.para_black {
  font-size: 16px;
  line-height: 30px;
  font-family: circular-regular;
}
.registration_title {
  color: var(--secondary-font-color);
  font-size: 16px;
  font-family: circular-medium;
}
.select_profession,
.terms_content,
.select_image_caption {
  color: grey;
  font-size: 14px;
  font-family: circular-regular;
  text-align: center;
  padding: 10px 0px;
}
.select_profession {
  text-align: initial !important;
}

.terms {
  border: 1px solid lightgrey;
  padding: 8px;
  border-radius: 8px;
  padding: 10px;
}
.terms_content {
  color: black;
  text-align: initial;
  line-height: 20px;
  padding: 0px;
}
.added_schools p {
  font-size: 16px;
  font-family: circular-regular;
}
.added_schools > p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}
.agree_checkbox span:nth-child(2) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.select_image {
  border: 1px solid lightgrey;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.image_label {
  position: absolute;
  top: -8px;
  left: 5px;
  background: white;
  padding: 0px 3px !important;
}
.image_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  font-family: circular-regular;
  text-align: center;
  display: grid;
  place-items: center;
}
.step {
  color: var(--secondary-font-color);
}
