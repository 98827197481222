.local_video_container,
.remote_video_container {
	background: black;
	height: 100%;
}
.disable_local_container {
	display: none;
}

.local_screen_share_track {
	display: none !important;
}
video {
	height: 100vh;
	object-fit: cover;
}
.inactive_local_container {
	width: 150px;
	height: 150px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 50;
	font-family: circular-regular;
}
.student_remote_video_container video {
	height: 100% !important;
}
.inactive_local_container video,
.inactive_remote_video_container video {
	height: 100%;
}
.inactive_timer_container {
	display: none;
}
.active_timer_container {
	position: fixed;
	display: flex;
	align-items: center;
	gap: 6px;
	top: 0;
	left: 0;
	padding: 0.5rem 1rem;
	border-radius: 0px 8px 0px 8px;
	background-color: rgb(255, 255, 255);
	color: black;
	font-weight: bolder;
	z-index: 50;
}
.screenshare_modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: black;
	width: 100%;
	height: 100%;
}

.arrow_close {
	position: absolute;
	right: 5px;
	top: 5px;
	padding: 0.3rem 0.5rem;
	background: whitesmoke;
	border: none;
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	z-index: 1000;
}

@media (max-width: 768px) {
	.inactive_local_container {
		width: 140px;
		height: 140px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 50;
	}

	video {
		height: 70vh;
		object-fit: cover;
	}
}
